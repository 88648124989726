import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';

import './B11U1L2.scss';

export default class B11U1L2 extends Component {

  render() {
    
    return (
  
      <Grid>
         <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
  <h1 className="intermidate">Class Materials</h1>
    <h2 className="intermidate">Teacher Training</h2>
  
    <div class="center"> 
        <Button href='https://teacher-training-m2.comligospanish.com/' className="btn-Intermidate" >Module 2</Button>   
       
        {/* <Button href='' download className="btn-download" size='medium'>Beginner 2</Button>  */} 
    </div>
   
        </Grid.Column>
        <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

</Grid>
    );
  }
}
