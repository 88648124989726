import React from 'react';
import { Switch, Route } from 'react-router-dom';
import module1 from '../pages/B11U1L1/B11U1L1';
import module2 from '../pages/B11U1L2/B11U1L2';
import advanced from '../pages/B11U1L3/B11U1L3';
// import l1work from '../pages/L1Work/L1work';
// import l2work from '../pages/L2work/L2work';
// import lesson4 from '../pages/B11U1L4/B11U1L4';
// import U1test from '../pages/U1Test/U1Test';
// import Videos from '../pages/Videos/Videos';


export default function Routes() {

 return(

    <Switch>
      {/* <Route path='/Repositories' component={Repositories} /> */}
      <Route path='/module1' component={module1} />
      <Route path='/module2' component={module2} />

     
      {/* <Route path='/l1work' component={l1work} /> 
      <Route path='/l2work' component={l2work} /> 
      <Route path='/lesson4' component={lesson4} />
      <Route path='/U1test' component={U1test} />
      <Route path='/Videos' component={Videos} />
     */}
    </Switch>
 );

}

