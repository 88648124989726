import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';

// import '../../style.scss';
import './B11U1L3.scss';

export default class B11U1L3 extends Component {
  render() {
   
    return (

<Grid>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <h1 className="advanced">Class Materials</h1>
    <h2 className="advanced">Spanish diversity advanced levels</h2>
  
    <div class="center"> 
        <Button href='https://diversity-adv-one.comligospanish.com/' className="btn-advanced" >Advanced 1</Button>   
       
        {/* <Button href='' download className="btn-download" size='medium'>Beginner 2</Button>  */} 
    </div>
    <div class="center"> 
    <Button href='https://diversity-adv-two.comligospanish.com/'  className="btn-advanced-dos" >Advanced  2</Button> 
    </div>
    <div class="center"> 
    <Button href='https://diversity-adv-three.comligospanish.com/'  className="btn-advanced-tres" >Advanced  3</Button> 
    </div>
    <div class="center"> 
    <Button href='https://diversity-adv-four.comligospanish.com/'  className="btn-advanced-cuatro" >Advanced  4</Button> 
    </div>
       

</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

  
    );
  }
}


    